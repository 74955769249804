import React, { useRef,useEffect ,useState} from "react";
import Moment from 'react-moment';

import LOGO from '../Assets/plsprint.png'
import {AiFillPrinter} from 'react-icons/ai';
import styled from 'styled-components'
import ReactToPrint from "react-to-print";
import TextareaAutosize from 'react-textarea-autosize';
import { Img, Label, currency } from "../utilities/Shared";


export const Wrapper = styled.div`
padding:20px 40px;
font-size:.8rem;
font-weight:bold;
text-transform:capitalize;
`;
export const Input = styled.input`

position: ${props => props.position || "relative"};

width:${props => props.width || "90%"};
padding:${props => props.padding || "5px 3px 0px 3px"};
max-width:${props => props.maxWidth || "100%"};
margin:${props => props.margin || "0px 0px 0px 0px"};
height: ${props => props.height || ""};
font-size:${props => props.fontSize || ".8rem"};
text-align: ${props => props.textalign || "center"};
font-weight: ${props => props.fontWeight || "bold"};

outline:none;
border:none;
border-bottom:1px solid grey;
border-radius:5px;
:focus{
  border:2px solid var(--plsGreen);
}
`;
export const Flexed = styled.div`
width:${props => props.width || "100%"};
display:flex;
justify-content:${props => props.justifyContent || "flex-end"};
align-items:${props => props.alignItems || "flex-start"};
position: ${props => props.position || "relative"};
padding: ${props => props.padding || "3px 0px"};
bottom: ${props => props.bottom || ""};
font-size:${props => props.fontSize || ""};
margin:${props => props.margin || ""};
border-bottom: ${props => props.borderBottom || ""};
`;

export const Flex = styled.div`
width:${props => props.width || "100%"};
position: ${props => props.position || "relative"};
flex:${props => props.flex || "1"};
white-space: ${props => props.whiteSpace || "nowrap"};
flex: ${props => props.flex || "1"};
border-bottom: ${props => props.border ? "1px solid black" : ""};
text-align:${props => props.textAlign || "left"};
padding: ${props => props.padding || "0"};
font-size:${props => props.fontSize || ""};


h2{
    margin:0;
    padding:0:
}
`;




export const Textarea = styled(TextareaAutosize)`

min-height:${props =>props.minHeight || "50px"};
width:${props =>props.width || "100%"};
outline:none;
text-align:center;
border:1px solid #c8c8c8;
font-size:calc(.7rem + .5vw);
padding:${props =>props.padding || "8px"};
margin:${props =>props.margin || "0"};
resize:none;
font-weight:bold;
border-bottom:1px solid black;
:focus{
    border:2px solid var(--plsGreen);
  }

`;
export const Headerspan = styled.div`
width:100%;
text-align:center;
font-size:1.1rem;
`;


export default function PrintComponent({customerinfo,jobinfo,billable,maincalcs,mainlocation}) {
    let componentRef = useRef();



console.log(mainlocation)


  return (
    <>
      <Flex id="print_component">
        {/* button to trigger printing of target component */}
        <ReactToPrint

          trigger={() => <AiFillPrinter style={{fontSize:"calc(1.4rem + .5vw)"}} />}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <Flex style={{ display: "none" }}>
          <ComponentToPrint customerinfo={customerinfo} mainlocation={mainlocation} jobinfo={jobinfo} billable={billable} maincalcs={maincalcs} ref={(el) => (componentRef = el)} />
        </Flex>
      </Flex>
    </>
  );
}




// component to be printed
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);
     

  }

  render() {
    return (
      
     <Wrapper >
 
<Flexed margin="25px 0px 0px 0px">
    <Flex><Img src={LOGO} alt="Logo" /></Flex>
    <Flex padding="0px 0px 0px 30px" fontSize="1.7rem">4250 118th Ave N<br />Clearwater, Fl 33762<br />Phone: 727-573-1763<br />Fax: 727-573-2662<br />www.plsprint.com</Flex>
    <Flex flex="1.4" style={{fontSize:"1.9rem",textAlign:"center",top:"10px",right:"30px"}}>Job<br />Acknowledgement</Flex>
</Flexed>
<Flexed margin="30px 0px 0px 0px">
    <Flex fontSize="1.7rem">SOLD TO<br />
    <span style={{fontSize:"1.4rem"}}>
        {this.props.customerinfo?.name}<br />
        {this.props.mainlocation?.address1}<br />
        {this.props.mainlocation?.address2 && <>{this.props.mainlocation?.address2}<br /></>}
        {this.props.mainlocation?.city} {this.props.mainlocation?.state}, {this.props.mainlocation?.zip}<br />
        Phone: {this.props.mainlocation?.phone}
    </span>
    </Flex>

    <Flex fontSize="1.7rem">Sales Rep<br />
    <span style={{fontSize:"1.4rem"}}>
        {this.props.customerinfo?.salesrep?.firstName} {this.props.customerinfo?.salesrep?.lastName}
        </span><br /> <br />BILLING TERMS<br />
        <span style={{fontSize:"1.4rem"}}>
        {this.props.customerinfo?.billingterms}
        </span>
        </Flex>

    <Flex fontSize="1.7rem" flex="0">
        JOB NO.<br />
        <span style={{fontSize:"1.4rem"}}>{this.props.jobinfo?.job}</span><br /><br />
        Due Date<br />
        <span style={{fontSize:"1.4rem"}}><Moment style={{fontSize:"1.4rem"}} format="MM/DD/YYYY">{this.props.jobinfo?.duedate}</Moment></span><br />
        </Flex>
</Flexed>

<Flexed style={{marginTop:"20px"}}>
    <Flex flex="2.5"><span style={{fontSize:"1.7rem"}}>Job Title:</span>  <span style={{fontSize:"1.4rem"}}>{this.props.jobinfo?.jobname}</span></Flex>
    <Flex><span style={{fontSize:"1.7rem"}}>P.O.#:</span>  <span style={{fontSize:"1.4rem"}}>{this.props.jobinfo?.custpo}</span></Flex>
    <Flex><span style={{fontSize:"1.7rem"}}>Date Entered:</span>  <span style={{fontSize:"1.4rem"}}><Moment style={{fontSize:"1.4rem"}} format="MM/DD/YYYY">{this.props.jobinfo?.dateentered}</Moment></span></Flex>
</Flexed>

<Flexed style={{background:"rgb(0,0,0,.2)"}}  padding="5px ">
    <Flex flex="3" style={{fontSize:"1.7rem"}}>Item</Flex>
    <Flex style={{fontSize:"1.7rem"}}>Quantity</Flex>
    <Flex style={{fontSize:"1.7rem"}}>Unit Cost</Flex>
    <Flex style={{fontSize:"1.7rem"}}>Total</Flex>
</Flexed>

{this.props.billable?.map(item => {
 return  <Flexed borderBottom="1px solid grey" padding="15px 0px">
   <Flex whiteSpace="wrap" flex="3" style={{fontSize:"1.4rem"}}>{item?.description}</Flex>
   <Flex style={{fontSize:"1.4rem"}}>{item?.quantity}</Flex>
   <Flex style={{fontSize:"1.4rem"}}>{parseFloat(item?.unitprice)?.toFixed(6)}</Flex>
   <Flex style={{fontSize:"1.4rem"}}>${currency(item?.cost)}</Flex>
</Flexed>
})
 
}

<Flexed   padding="5px ">
    <Flex flex="3" style={{fontSize:"1.4rem"}}></Flex>
    <Flex style={{fontSize:"1.4rem"}}>{this.props.maincalcs?.quantity}</Flex>
    <Flex style={{fontSize:"1.4rem"}} textAlign="right">Total Billed:&nbsp;&nbsp;</Flex>
    <Flex style={{fontSize:"1.4rem"}}>${currency(this.props.maincalcs?.price)}</Flex>
</Flexed>




    </Wrapper>

  
    );
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////
