
import { createContext, useState, useEffect, useContext, useRef } from "react"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import io from 'socket.io-client'

//const socket = io.connect("ws://plschat-28e821c6ffbd.herokuapp.com")



export const NotificationContext = createContext();

export const NotificationProvider = ({children}) => {

// const [onlineUsers, setOnlineUsers] = useState([])
// const socket = useRef()
// const [users, setUsers] = useState([])
// const [unread, setUnread] = useState([])
// const [arriv, setArriv] = useState(null)







// useEffect(() => {
//    socket.current = io.connect("https://plschat-28e821c6ffbd.herokuapp.com")
//    //socket.current = io.connect("ws://localhost:5001")
// },[])



// const getAllMessages = async() => {
//   const info = {
//     id:userInfo._id
//   }
// const getmessages = await axios.post('https://highgroundapi.herokuapp.com/chat/all/messages',info)
// .then(res => setUnread(res.data.filter(item => item.read === false)))
// .catch(err => console.log(err))
// }


// useEffect(() => {

//  // console.log('yeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee')
// },[arriv])


//  useEffect(() => {
//     socket.current.emit("addUser",userInfo?._id )
//     socket.current.on("getUsers", users => {
//         setUsers(users)
       
//     })
//     getAllMessages()
//  },[userInfo,arriv])

// /*
// useEffect(() => {
// socket.current?.on("welcome", message => {
//     console.log(message)
// })
// },[socket])
//  */

// const sendsinglemessage = (sendto,text) => {
// if(users.find(user => user.userId === sendto)){
//     socket.current.emit('sendmessage', {
//         senderId: userInfo._id,
//         receiverId:sendto,
//         message:text, 
//           })
// }  
// }

// const newmessagecount = (sendto,text) => {

//         socket.current.emit('newmessagesent', {
//             senderId: userInfo._id,
//             receiverId:sendto,
//             message:text, 
//               })
     
//     }

//     socket.current?.on('newnotice', data => {
//    setArriv({
//     message:data.message,
//     time:Date.now()
//    })



//   })








  return (
    <NotificationContext.Provider value={{}}>
          
        {children}
    </NotificationContext.Provider>
  )
}

export default NotificationContext