
import { Tab, TabList, Tabs } from 'react-tabs'
import { useContext, useEffect, useState } from 'react'
import { Button, Flex, Flexed, MainWrap, Scroll, TBody, THead, Table, Td, Th, Tr, Wrap } from '../../utilities/Shared'
import axios from 'axios'
import { sortdate, sortit, sortnum } from '../../utilities/Sort'
import Moment from 'react-moment'
import { FaCopy } from "react-icons/fa";
import { changeidtopress, colorchange, getemployeebynamefromempid } from '../../utilities/Convertions'
import { toast } from 'react-toastify';
import StitchingContext from '../../Context/StitchingContext'
import BinderySchedulePrint from '../../Printing/BindirySchedule'
const Bindery = ({activejobs,jobids,employeelist}) => {
 

const [jobs, setJobs] = useState()
const [imp, setImp] = useState([])
const [newer, setNewer] = useState([])
const [ops, setOps] = useState([])
const [stids, setStids] = useState([])
const [order, setOrder] = useState("ASC")
const [reset, setReset] = useState(false)
const [impo, setImpo] = useState([])

// const {stitchinginfo,getstitchingtime}  = useContext(StitchingContext)

// console.log(stitchinginfo)
const [employees, setEmployees] = useState([])


const config = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':'*' 
  }
};


useEffect(() => {
  let stitchjobs = []
  let stitchids = []
activejobs.filter(filt => parseInt(filt.stitchup) > 0).map(item => {
  stitchjobs.push(item)
  stitchids.push(item.job)
}
  )
  setStids(stitchids)
  setJobs(activejobs)
  getest()
  getstitchops() 
},[])

useEffect(() => {


  getest()

},[stids])

useEffect(() => {
  getstitchops() 
},[impo])



const getest = async() => {

  let r = {
    quote:"123456"
  }
  await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getallimpo.php`,r,config)
.then(res => {
 setImpo(res.data)

})
.catch(err => console.log(err))
}
     




console.log(impo)
const getstitchops = async() => {

  let r = {
    ids:jobids
  }
  await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getoperationsforactivejobs.php`,r,config)
            .then(res => {
              let ret
             
             
              let ter  = []
              jobs.map(item => {
                let cuts = 0
                let folds = ""
           let pressid = 0
           let pressname = "Digital"
              let uu =   res.data?.filter(filt => parseInt(filt.job) === parseInt(item.job))
              let imps =   impo?.filter(filt => parseInt(filt.quote) === parseInt(item.quote))

              console.log(imps)
imps?.map(item => {
  
  if(parseInt(item.cuts) > 0){
cuts += parseInt(item.cuts)
  }
  if(parseInt(item.foldsup) > 0){
    folds = parseInt(item.foldtype) || "Yes"
      }
      if(parseInt(item.press) > 0){
        pressname =  changeidtopress(item.press) 
        console.log(pressname)
pressid = item.press
          }
     
})

              


              uu?.some(cat => cat.name === "Off Tripping") && ter.push(item)
              uu[0]?.name === undefined ? ret = "On Incoming" : ret = uu[0]?.name
             item.cuts = cuts
              item.folds = folds
              item.stat=uu[0]?.name || " On Incoming"
              item.quantity = parseInt(item.quantity)
           item.press = pressid
           item.pressname = pressname
            //   console.log(getstitchingtime(item.quantity,item.stitchup,item.stitchpockets))
            //   let hh = getstitchingtime(item.quantity,item.stitchup,item.stitchpockets)
            // item.esttime= hh
              })
              console.log(ter)
             let uuu = jobs.filter(ar => !ter.find(rm => (rm.job === ar.job) ))
            
        
             setJobs(uuu)
              
            })
            .catch(error => { console.log(error)})
}



  const CopyAllToClipboard = () => {
    var tb = document.getElementById('m110');
    tb.getElementsByTagName("thead")[0].style.display = "none";
    var range = document.createRange();
                 range.selectNode(document.getElementById('m110'));
                 window.getSelection().removeAllRanges();
                 window.getSelection().addRange(range);
                 document.execCommand("copy");
                 toast.success(`Copied Job To Clipboard`)
                 tb.getElementsByTagName("thead")[0].style.display = "block";
         }

const CopyToClipboard = (containerid) => {
    var tb = document.getElementById(containerid);
 tb.getElementsByTagName("td")[0].style.display = "none";
  var range = document.createRange();
               range.selectNode(document.getElementById(containerid));
               window.getSelection().removeAllRanges();
               window.getSelection().addRange(range);
              
               document.execCommand("copy");
               tb.getElementsByTagName("td")[0].style.display = "none";
               toast.success(`Copied Job To Clipboard`)
               tb.getElementsByTagName("td")[0].style.display = "block";
         //showTH(containerid);
         }	

console.log(jobs)
  
  return (
    <Wrap padding="0">

 <Flexed  margin="5px 0px 0px 0px" bgColor="#0E425A" color="white"  padding="5px"><Flex fontSize="calc(1.1rem + .4vw)">Bindery Active Jobs - {jobs?.length}</Flex>
 <Flex  margin="-10px 30px 0px 0px" textAlign="right" flex="0"><BinderySchedulePrint jobs={jobs} /></Flex>
        <Flex textAlign="right" flex="0"><Button bgcolor="orange" onClick={e => CopyAllToClipboard()}>Copy Table</Button></Flex>
        </Flexed>
        <Scroll height="70vh"> 
<Table id="m110" >
<THead gap="8px">
<Tr >
<Th flex=".6">&nbsp;</Th>
<Th flex=".8" onClick={e => sortnum('job',order,jobs,setJobs,setOrder)} className="hand">Job #</Th>
<Th flex="4" className="hand" onClick={e => sortit('customer',order,jobs,setJobs,setOrder)}>Customer</Th>
<Th flex="4" className="hand" onClick={e => sortit('jobname',order,jobs,setJobs,setOrder)}>Job Name</Th>
<Th flex="4" className="hand" onClick={e => sortit('pressname',order,jobs,setJobs,setOrder)}>Press</Th>
<Th flex="1" className="hand" onClick={e => sortit('cuts',order,jobs,setJobs,setOrder)} >Cut</Th>
<Th flex="1" className="hand" onClick={e => sortit('folds',order,jobs,setJobs,setOrder)} >Fold</Th>
<Th flex="1" className="hand" onClick={e => sortit('stitchup',order,jobs,setJobs,setOrder)} >Stitch</Th>
<Th flex="1"  className="hand" onClick={e => sortit('quantity',order,jobs,setJobs,setOrder)} >Quantity</Th>
<Th onClick={e => sortit('salesrep',order,jobs,setJobs,setOrder)}  className="hand">Sales Rep</Th>
<Th flex="1.5" onClick={e => sortdate('dateentered',order,jobs,setJobs,setOrder)} className="hand">Enter Date</Th>
<Th flex="1.5" onClick={e => sortdate('duedate',order,jobs,setJobs,setOrder)}  className="hand">Due Date</Th>
<Th flex="1.5" onClick={e => sortit('stat',order,jobs,setJobs,setOrder)}  className="hand">Status</Th>

</Tr></THead>
 <TBody hbgcolor="#0E425A" gap="8px">
 {jobs?.map((job,index) => {
 return <Tr id={`m110${index}`} className="hand" hcolor="white">
<Td flex=".6"><button onClick={e => CopyToClipboard(`m110${index}`)} class="" className="hand" >Copy</button></Td>
<Td flex=".8"><a className="hand" href={`/jobticket/${job.job}`} >{job.job}</a></Td>
<Td flex="4">{job.customer}</Td>
<Td flex="4" padding="0px 5px 0px 0px">{job.jobname}</Td>
<Td flex="4">{job.pressname}</Td>
<Td flex="1">{job.cuts > 0 && <b>{job.cuts}</b>}</Td>
<Td flex="1">{job.folds > 0 && <b>{job.folds}</b>}</Td>
<Td flex="1">{job.stitchup > 0 && <b>Yes</b>}</Td>
<Td flex="1"  >{job.quantity}</Td>
<Td flex="1">{getemployeebynamefromempid(job?.salesrep,employeelist)}</Td>
<Td flex="1.5"><Moment format="MM/DD/YYYY">{job.dateentered}</Moment></Td>
<Td flex="1.5"><Moment format="MM/DD/YYYY">{new Date(job.duedate)}</Moment></Td>
<Td flex="1.5" bgColor={colorchange(job.stat)} padding="3px">{job.stat}</Td>
</Tr>
 })
}
<Tr>
<Td flex=".6"></Td>
<Td flex=".8"></Td>
<Td flex="3"></Td>
<Td flex="1.5"></Td>
<Td ></Td>
<Td > </Td>
<Td ></Td>
<Td flex="1.5"></Td>
<Td flex="3"></Td>
<Td ></Td>
<Td flex="1.5"></Td>
<Td flex="1.5"></Td>
<Td flex="1.5"></Td>
</Tr>
 </TBody>
 </Table>
</Scroll>






    {/*}
    <Flexed  margin="30px 0px 0px 0px" bgColor="#0E425A" color="white"  padding="5px"><Flex fontSize="calc(1.1rem + .4vw)">M110 Active Jobs - {jobs?.length}</Flex></Flexed>
    <Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"white"}} gap="0px 10px" className="hand">
    <Flex flex=".1"></Flex>
      <Flex fontSize="var(--headerMedium)" onClick={e => sortnum('jobs',order,jobs,setJobs,setOrder)}>Job#</Flex>
      <Flex flex="2" fontSize="var(--headerMedium)" >Customer</Flex>
      <Flex  flex="1.5" fontSize="var(--headerMedium)" >Component</Flex>
      <Flex  flex="1.5" fontSize="var(--headerMedium)" >Sheet Size</Flex>
      <Flex  fontSize="var(--headerMedium)">Quantity</Flex>
      <Flex  fontSize="var(--headerMedium)">Run Type</Flex>
      <Flex  fontSize="var(--headerMedium)">Description</Flex>
      <Flex  fontSize="var(--headerMedium)">Sales Rep</Flex>
      <Flex  fontSize="var(--headerMedium)" >Entered</Flex>
      <Flex  fontSize="var(--headerMedium)" >Due</Flex>
      <Flex  fontSize="var(--headerMedium)">Status</Flex>
    
    </Flexed>
    {!jobs ? <>LOADING</> : <>
    <Scroll  height="78vh" border="1px solid #303030">
    {
     jobs?.map(job => {
        return <Flexed className="normalrows hand" padding="5px" gap="0px 10px" hoverbgcolor="#0E425A" hovercolor="white">
          <Flex  flex=".1"><FaCopy style={{fontSize:"calc(1rem + .2vw)"}} onClick={e => navigator.clipboard.writeText(`${job.job} ${job?.impp[0]?.customer} ${job.name} ${job.sheetl} X ${job.sheetw} ' ' ${job.newquantity} ${job.method} ${job?.impp[0]?.dateentered} ${job?.impp[0]?.duedate} ${job.stat}`)} /></Flex>
          <Flex ><a className="hand" href={`/jobticket/${job.job}`} >{job.job}</a></Flex>
          <Flex flex="2">{job?.impp[0]?.customer}</Flex>
          <Flex flex="1.5">{job.name}</Flex>
          <Flex flex="1.5">{job.sheetl} X {job.sheetw}</Flex>
          <Flex>{Math.ceil(job.newquantity)}</Flex>
          <Flex>{job.method}</Flex>
          <Flex>{job?.impp[0]?.jobname}</Flex>
                    <Flex>{getemployeebynamefromempid(job?.impp[0]?.salesrep,employees)}</Flex>
          <Flex><Moment format="MM/DD/YYYY">{job.impp?.dateentered}</Moment></Flex>
          <Flex><Moment format="MM/DD/YYYY">{job.impp?.duedate}</Moment></Flex>
          <Flex>{job.stat}</Flex>
        
          </Flexed>
      })
    }
    </Scroll>
</> }*/}


    </Wrap>
  )
}

export default Bindery



