import React, { useState } from 'react'
import { Flexed,Flex, currency, Textarea } from '../utilities/Shared'
import Moment from 'react-moment'
import ReceiveItem from '../pages/po/ReceiveItem'
import Modal from '../modals/Modal'
import {GiReceiveMoney} from 'react-icons/gi'


const InventoryBox = ({items,setupdateitem,poinfo,reset,setReset}) => {

const [receiveshow, setReceiveshow] = useState(false)
const [receiveitem, setReceiveitem] = useState({})




  return (
<div style={{position:"relative",width:"100%",overflow:"scroll",minHeight:"200px",marginTop:"5px"}}>
<Modal show={receiveshow} header={`Receive Item - ${receiveitem.name}`} onCancel={() => {setReceiveshow(false)}}><ReceiveItem item={receiveitem} poinfo={poinfo} reset={reset} setReset={setReset} onCancel={() => {setReceiveshow(false)}}/></Modal>
<Flexed width="200%" style={{background:"#0E425A",color:"white",padding:"5px"}} gap="20px">
  <Flex flex=".5"></Flex>
  <Flex flex="2">Item</Flex>
  <Flex flex="2">Description</Flex>
  <Flex flex="1">Outstanding</Flex>
<Flex flex="1">Ordered</Flex>
<Flex flex="1">Received</Flex>
    <Flex>Order Date</Flex>
    <Flex>Receive Date</Flex>
    <Flex>Size</Flex>
    <Flex>Type</Flex>
    <Flex>Unit Price</Flex>
    <Flex>Order Unit</Flex>
    <Flex>Price</Flex>
</Flexed>

{
    items?.map(item => {
      console.log(item)
     return  <> 
    
     <Flexed width="200%" gap="20px" padding="5px" onClick={e => item.recievedstatus === 1 ? "" : setupdateitem(item)} className={item.recievedstatus === 1 ? "receivedrow" : "normalrow"} >

    <Flex flex=".5">{item.recievedstatus === 1 ? "" : <GiReceiveMoney style={{fontSize:"20px"}} onClick={() => {setReceiveitem(item);setReceiveshow(true)}} />}</Flex>

    <Flex  flex="2">{item?.name}</Flex>
    <Flex flex="2" >{item.description?.replace(/&amp;/g, "&")}</Flex>
    <Flex flex="1">{item?.recievedoutstanding > 0 ? <div style={{color:"red",fontSize:"calc(.9rem + .2vw)",display:"flex",alignItems:"flex-start",padding:"0",margin:"0",fontWeight:"bold"}}>{item?.recievedoutstanding}</div> : 0}</Flex>
    <Flex  flex="1">{item?.quantity}&nbsp;&nbsp;{item?.orderby}</Flex>
    <Flex  flex="1">{item?.recievedquantity && <>{item.recievedquantity}&nbsp;&nbsp;{item?.orderby}</>}</Flex>
 
    
    
    <Flex><Moment format="MM/DD/YYYY">{poinfo?.createdAt}</Moment></Flex>
    <Flex>{item?.recieveddate ? <Moment format="MM/DD/YYYY">{item?.recieveddate}</Moment> : ""}</Flex>
    <Flex>{item?.width && item?.length ? item.width+" X ": item.width} {item?.length ? item.length: ""}</Flex>
    <Flex>{item?.type || "N/A"}</Flex>
    <Flex>{item?.unitprice}</Flex>
    <Flex>{item?.priceby}</Flex>
    <Flex>{currency(item?.extprice)}</Flex>   
    </Flexed> 
    </>
    })
}
</div>
  )
}

export default InventoryBox