import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Datepick, Flex, Flexed, Img, Input, Scroll, Textarea, Textauto } from '../../utilities/Shared'
import LOGO from '../../Assets/plsprint.png'
import Moment from 'react-moment'
import { getemployeebynamefromempid } from '../../utilities/Convertions'
import UserContext from '../../Context/UserContext'
import Collapse from '../../utilities/Collapse'
import ShippingTicket from '../../Printing/ShippingTicket'
import axios from 'axios'



const Setupshipment = ({item,customerinfo,jobinfo,onCancel,setJobinfo}) => {
const defaultitem = {
    addedid:"",
    description:"",
    include:false,
    itemid:"",
    name:"",
    numtype:"",
    pertype:"",
    requested:"",
    sending:"",
    shipdate:new Date(),
    shipmethod:"",
    type:""

}

    const[addy, setAddy] = useState({})
    const[billto, setBillto] = useState({})
    const[packlist, setPacklist] = useState([])
    const[process, setProcess] = useState(false)
    const[newitems, setNewitems] = useState()
    const[origlist, setOriglist] = useState()
    const[newlist, setNewlist] = useState([])
    const[reset, setReset] = useState([])
    const[method, setMethod] = useState("")
    const [search, setSearch] = useState("")
    const [duedate, setDuedate] = useState(null)
    const [shippeddate, setShippeddate] = useState(new Date())
    const[tempaddress, setTempaddress] = useState({})

    const [currentitem, setCurrentitem] = useState(defaultitem)


    const { employees } = useContext(UserContext)
const line = useRef()


useEffect(() => {
    setNewitems(item.items)
},[])





   useEffect(() => {
    setdesc()
   },[tempaddress?.sending,tempaddress?.pertype,tempaddress?.type])


   
   const setdesc = () => {
    item.description = ""

    let letdesc
    let nums = 0
    let q = parseInt(tempaddress?.sending) || 0
let per = parseInt(tempaddress?.pertype) || 0
let a = parseInt(q / per)


let b = q % per

nums = a

letdesc = a+" "+tempaddress?.type+" @ "+tempaddress?.pertype+" each"

// if(q > 0 && per > 0){
//     letdesc = Math.ceil(a)+" "+item.type+" @ "+item.pertype+" each"; 
    if(b > 0){
        letdesc += "\n 1 Box @ "+b+" each"; 
        nums += 1
    }
if(tempaddress?.sending > 0 && tempaddress?.pertype > 0){
    setTempaddress({...tempaddress,description:letdesc,numtype:nums})
}
  
   }



console.log(jobinfo)
  
 const setpack = (e,item) => {

    if(tempaddress.name > "" && tempaddress.sending > "" && tempaddress.type > "" && tempaddress.pertype > "" && tempaddress.description > ""){
        setPacklist([...packlist,tempaddress])
        setTempaddress(defaultitem)
    }
 
 }


const removeitem = (item) => {
    let y = packlist.filter(filt => filt.itemid !== item.itemid)
    setPacklist(y)
}




const saveshipping = (shipid) => {

    let r = {
       job:jobinfo._id,
       jobnum:jobinfo.job,
       shippeditems:packlist,
       shipdate:shippeddate,
       shippingid:shipid,
       customerid:customerinfo._id,
       shipto:item.locationid,
       method

    }
console.log(r)
    const saveshipped = async(r) => {
        await axios.post('https://highgroundapi.herokuapp.com/ship/add',r)
        .then(res => {
       
            setPacklist([])
           onCancel()
        })
       .catch(err => console.log(err))
    }
 saveshipped(r)           

  
}


const settemplocationdropdown =(e,node,field) => {

    var inputValue = e.target.value;
    // setSelectedItem({...selectedItem, itemname: e.target.value})
     let id;
     let dd;
  
         var options = document.getElementById(node).childNodes;
  
         for (var i = 0; i < options.length; i++) {

           if (options[i].value === inputValue) {
            let addressdata = options[i].dataset
      
             dd ={
              ...addressdata,
             
            }
           }
    } 

console.log(dd)
if(!dd?.name ){
   
    let y = {
        ...dd,
        name:inputValue
    }
dd= y
}
  setTempaddress(dd)
  }

 // console.log(newitems)
console.log(tempaddress)


 
  return ( 
    <div style={{background:"white",position:"relative",color:"#0E425A",padding:"5px"}}>
        <div style={{position:"absolute",top:"-40px",right:"10px"}}><Button onClick={e => onCancel()}>Close</Button></div>
<Flexed margin="10px 0px 0px 0px">
    <Flex flex="1.5" fontSize="1.2rem"><span style={{fontWeight:"bold",fontSize:"1.4rem"}}>Sold To</span><br />{billto[0]?.name}<br />{billto[0]?.address1}{billto[0]?.address2 ? <><br />{billto[0]?.address2}</> : ''} <br />{billto[0]?.city}, {billto[0]?.state} {billto[0]?.zip}</Flex>
    <Flex flex="1.5" fontSize="1.2rem"><span style={{fontWeight:"bold",fontSize:"1.4rem"}}>Ship To</span><br />{item?.locationname ? item?.locationname : ""}<br />{item?.address1}{item?.address2 ? <><br />{item?.address2}</> : ''} <br />{item?.city}, {item?.state} {item?.zip}</Flex>
  
    <Flex  fontSize="1.2rem" padding="0px 10px"><span style={{fontWeight:"bold",fontSize:"1.4rem"}}>Job No.</span><br />{jobinfo.job}<br /><br /><span style={{fontWeight:"bold",fontSize:"1.4rem"}}>Ship Date</span><br />
    <Datepick selected={shippeddate}  onChange={(date) => setShippeddate(date)} /> </Flex>
    <Flex  flex=".5" fontSize="1.2rem" padding="0px 10px"><span style={{fontWeight:"bold",fontSize:"1.4rem"}}>Shipping&nbsp;#</span><br />On Save</Flex>

</Flexed>
<Flexed padding="10px 0px 0px 0px" margin="15px 0px 0px 0px">
    <Flex fontSize="1.4rem"><span style={{fontWeight:"bold",fontSize:"1.4rem"}}>Job Title:</span>{jobinfo.jobname}</Flex>
    <Flex padding="10px">< ShippingTicket  method={method} packlist={packlist} billto={billto} jobinfo={jobinfo} item={item} employees={employees} saveshipping={saveshipping}/> </Flex>
    </Flexed>
        <Flexed margin="0px 0px 0px 0px" padding="10px 0px" style={{background:"rgb(0,0,0,.1)",border:"1px solid black",fontWeight:"bold"}} >
            <Flex fontSize="1.4rem"  textAlign="center">Sales Rep</Flex>
            <Flex fontSize="1.4rem"  textAlign="center">P.O. #</Flex>
            <Flex fontSize="1.4rem"  textAlign="center">Order Date </Flex>
            <Flex fontSize="1.4rem"  textAlign="center">Terms</Flex>
            <Flex fontSize="1.4rem"  textAlign="center">Ship Via</Flex>
     
        </Flexed>
        <Flexed padding="10px 0px" >
              <Flex fontSize="1.4rem"  textAlign="center" >{getemployeebynamefromempid(jobinfo.salesrep,employees) }</Flex>
            <Flex fontSize="1.4rem"  textAlign="center"><Input type="text" value={jobinfo.custpo} onChange={e => setJobinfo({...jobinfo,custpo:e.target.value})}/>{}</Flex>
            <Flex fontSize="1.4rem"  textAlign="center"><Moment style={{fontSize:"1.4rem"}} format="MM/DD/YYYY">{jobinfo.dateentered}</Moment></Flex>
            <Flex fontSize="1.4rem"  textAlign="center"> Net 30</Flex>
            <Flex fontSize="1.4rem"  textAlign="center"><Input type="text" value={method} onChange={e => setMethod(e.target.value)} placeholder="Select Item"  list="shipmethod" />
   <datalist id="shipmethod">
   <option key={0} data-item="PLS Delivery" value="PLS Delivery"/> 
   <option key={1} data-item="UPS" value="UPS"/> 
   <option key={1} data-item="Fed Ex" value="Fed Ex"/>
   <option key={1} data-item="Freight" value="Freight"/>
   <option key={1} data-item="Customer Pick-Up" value="Customer Pick-Up"/>
   </datalist></Flex>
        </Flexed>

        <Flexed padding="10px 0px" style={{background:"rgb(0,0,0,.1)",border:"1px solid black",fontWeight:"bold"}} borderBottom="1px solid black">
              <Flex fontSize="1.4rem"  textAlign="left" flex="3">Description</Flex>
            <Flex fontSize="1.4rem"  textAlign="left" flex=".5">Ordered</Flex>
            <Flex fontSize="1.4rem"  textAlign="left" flex=".5">Sending</Flex>
            <Flex fontSize="1.4rem"  flex="1" textAlign="left">Type </Flex>
            <Flex fontSize="1.4rem"  flex=".5" textAlign="center">Per </Flex>
            <Flex fontSize="1.4rem"  flex=".5" textAlign="center"># Of</Flex>
            <Flex fontSize="1.4rem"  textAlign="left" flex="2">Details</Flex>
            <Flex fontSize="1.4rem"  textAlign="left" flex=".2"></Flex>
    
        </Flexed>
        <div style={{height:"300px"}}> 






<Flexed margin="25px 0px" >
    <Flex flex="3">
        <Input type="text"  placeholder="Select Item(s)" value={tempaddress?.name} onChange={e => settemplocationdropdown(e,"tempaddy")} list="tempaddy" />
   <datalist id="tempaddy">
{

    newitems?.map((item,index) => {
     return   <option key={index}  data-name={item?.name} data-itemid={item?.itemid}  data-requested={item?.requested} data-shipdate={item?.shipdate} data-method={item?.method} value={`${item?.name}`}/> 
    })
}
   </datalist>

{}
   </Flex>
   <Flex flex=".5"><Input type="text" value={tempaddress?.requested} onChange={e => setTempaddress({...tempaddress,requested:e.target.value})} /></Flex>
   <Flex flex=".5"><Input type="text" value={tempaddress?.sending} onChange={e => setTempaddress({...tempaddress,sending:e.target.value})} /></Flex>
   <Flex fontSize="1.7rem"  flex="1" textAlign="left">
    <select onChange={e => setTempaddress({...tempaddress,type:e.target.value})} value={tempaddress?.type}>
    <option key={0} value="">Select</option>
    <option key={0} value="Box(s)">Box(s) </option>
    <option key={1} value="Skid(s)"> Skid(s)</option>
    </select>
    </Flex>
   <Flex flex=".5"><Input type="text" onChange={e => setTempaddress({...tempaddress,pertype:e.target.value})} value={tempaddress?.pertype} /></Flex>
   <Flex flex=".5"><Input type="text" onChange={e => setTempaddress({...tempaddress,numtype:e.target.value})} value={tempaddress?.numtype} /></Flex>
   <Flex flex="2"><Textarea style={{fontSize:"1.4rem"}} placeholder="Details"  onChange={e => setTempaddress({...tempaddress,description:e.target.value})} width="75%" value={tempaddress?.description} /></Flex>
   <Flex fontSize="2rem"  textAlign="left" flex=".2" onClick={e => setpack()}>+</Flex>
   </Flexed>


<Flexed padding="10px 0px" margin="15px 0px 10px 0px" fontWeight="bold" borderBottom="1px solid black">
    <Flex fontSize="1.4rem">Sending Items</Flex>
</Flexed>
  <Scroll height="180px" >
{
    packlist?.map(item => {
        return  <Flexed key={item?.itemid} padding="3px 0px"  borderBottom="2px solid lightgrey">
        <Flex fontSize="1.4rem"  textAlign="left" flex="3">{item?.name}</Flex>
      <Flex fontSize="1.4rem"  textAlign="left" flex=".5">{item?.requested}</Flex>
      <Flex fontSize="1.4rem"  textAlign="left" flex=".5">{item?.sending}</Flex>
      <Flex fontSize="1.4rem"  flex="1" textAlign="left">{item?.type} </Flex>
      <Flex fontSize="1.4rem"  flex=".5" textAlign="center">{item?.pertype} </Flex>
      <Flex fontSize="1.4rem"  flex=".5" textAlign="center">{item?.numtype}</Flex>
      <Flex fontSize="1.4rem"  textAlign="left" flex="2"><Textarea style={{fontSize:"1.4rem",border:"0",outline:"0",padding:"0"}}>{item?.description}</Textarea></Flex>
      <Flex fontSize="2rem"  textAlign="left" flex=".2" ></Flex>

  </Flexed>
    }).reverse()
}
</Scroll>













        {/* <Scroll height="250px">


  


        {
            //</Scroll> newitems?.filter(itemm => {return search.toLowerCase() === '' ? itemm : itemm.name?.toLowerCase().includes(search.toLowerCase//())}).map((item, index) => {
            
              //  return   
              newitems?.filter(itemm => {return search.toLowerCase() === '' ? itemm : itemm.name?.toLowerCase().includes(search.toLowerCase())}).map((item,index) =>{

                return <>
       <div style={{background:item.include ? "rgb(122,187,67,.6)" : ""}}> <Flexed padding="10px 0px" margin="10px 0px 0px 0px"  >
 <Flex fontSize="1.7rem"  textAlign="left" flex="3"><Input type="text" value={item.name} onChange={e => setnewitem(e,item,index,'name')} /></Flex>
<Flex fontSize="1.7rem"  textAlign="left" flex=".5"><Input type="text" value={item.requested} onChange={e => setnewitem(e,item,index,'requested')} /></Flex>
<Flex fontSize="1.7rem"  textAlign="left" flex=".5"><Input type="text" value={item.sending} onChange={e => setCurrentitem({...currentitem,sending:e.target.value})} /></Flex>
<Flex fontSize="1.7rem"  flex="1" textAlign="left"><Input type="text" value={item.type}  onChange={e => setCurrentitem({...currentitem,type:e.target.value})} placeholder="Select Item"  list="shiptype" />
   <datalist id="shiptype">
   <option key={0} data-item="Box(s)" value="Box(s)"/> 
   <option key={1} data-item="Skid(s)" value="Skid(s)"/> 
   </datalist></Flex>
   <Flex fontSize="1.7rem"  textAlign="left" flex=".5"><Input type="text" value={item.pertype} onChange={e => setCurrentitem({...currentitem,pertype:e.target.value})}  /></Flex>
   <Flex fontSize="1.7rem"  textAlign="left" flex=".5"><Input type="text" value={item.numtype || currentitem.numtype} onChange={e => setnewitem(e,item,index,'numtype')}  /></Flex>
  
   <Flex fontSize="1.7rem"  textAlign="left" flex="2"><Textauto  minHeight="20px" padding="5px 0px" style={{fontSize:"1.4rem"}} value={item.desription || currentitem.desription} onBlur={e => setseconddesc(e,item,index,'description')} /></Flex>
   <Flex fontSize=".8rem"  textAlign="center" flex=".2"><Input type="checkbox" checked={item.include ? true : false}  onClick={e => {setpack(e,item)}}  /></Flex>
</Flexed>
<Flexed>
   <Flex fontSize="1.7rem"  textAlign="left" flex="2" style={{marginTop:"-15px"}}>
    
    <Collapse  title={<Flexed width="200px" ><Flex >Add Note</Flex></Flexed>} defaultExpanded={item.notes > "" ? true : false}>
        <Flexed>
            <Flex><Textarea style={{fontSize:"1.4rem"}} placeholder="Item Notes" value={item?.notes} onBlur={e => setnewitem(e,item,index,'notes')} width="75%" /></Flex>
        </Flexed>
    </Collapse>
       
    
    </Flex>
</Flexed>
</div>
</>
})

}
<br /><br /><br /><br /><br /><br /><br /><br />
</Scroll> */}
</div>
</div>
  )
}

export default Setupshipment