import React, { useContext, useEffect, useState } from 'react'
import {Wrapper} from '../utilities/Shared'
import Header from '../components/Header/Header'
import Main from './Main'
import Sidenav from './Sidenav'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import Supplies from './inventory/Supplies'
import Admin from './Admin/Admin'
import Pos from './po/Pos'
import Vendors from './po/vendor/Vendors'
import Vendor from './po/vendor/Vendor'
import Jobs from './jobs/Jobs'
import Purchase from './po/Purchase'
import HGPurchase from './po/HGPurchase'
import PLSNewPO from './po/PLS/PLSNewPO'
import Eddm from './Mailing/Eddm'
import Customers from './Customers/Customers'
import Customer from './Customers/Customer'
import Inhouse from './inventory/Inhouse'
import Paperitem from './inventory/Paperitem'
import { POProvider } from '../Context/POContext'
import HGCustomer from './Customers/HGCustomer'
import Closedpo from './po/Closedpo'
import Closedpurchase from './po/Closedpurchase'
import Locations from './inventory/Locations'
import Stockitem from '../pages/inventory/Stockitem'
import UserContext from '../Context/UserContext'
import Cookies from 'js-cookie';
import Estimater from './estimating/Estimater'
import Login from './Login'
import Employees from './Employees/Employees'
import Employee from './Employees/Employee'
import Search from './Search/Search'
import Timetrack from './Timetrack/Timetrack'
import JobTicket from './jobs/JobTicket'
import Acualvsestimate from './jobs/Acualvsestimate'
import Newpo from './po/Newpo'
import Ponew from './po/Ponew'
import Versus from './jobs/jobticket/Versus'
import Allocated from './inventory/Allocated'
import TEST from '../utilities/TEST'
import Chat from '../Chat/Chat'
import axios from 'axios'
import ChatContext, { ChatProvider } from '../Context/ChatContext'
import { JobticketProvider } from '../Context/JobticketContext'
import JobTicket1 from './jobs/Jobticket1'
import Joticketcheck from './jobs/jobticket/Joticketcheck'
import Ticketcheck from './jobs/Ticketcheck'
import Savejob from './jobs/Savejob'
import Gl40 from './Schedules/GL40/Gl40'
import Schedules from './Schedules/Schedules'
import Elections from '../Elections/Elections'
import Email from '../emails/Email'
import Shipping from './shipping/Shipping'
import Whitesheet from './whitesheet/Whitesheet'
import Shipticket from './shipping/Shipticket'
import { PressProvider } from '../Context/PressContext'
import Sitesettings from './Admin/Sitesettings'
import { StitchingProvider } from '../Context/StitchingContext'
import { EstimateProvider } from '../Context/EstimateContext'
import Dailytest from './Admin/Dailytest'
import Reports from './Reports/Reports'
import CFPNewpo from './po/CFPNewpo'
import Quoteletter from './Quotes/Quoteletter'
import Csv from '../components/CSV/Csv'
import SalesDash from './Sales/SalesDash'
import NewSchedule from './Schedules/NewSchedule'
import Calendar from '../components/Calendar/Calendar'
import Shipreprint from '../Printing/Shipreprint'
import RFQ from './RFQ/RFQ'
import CFPPO from './po/vendor/CFPPO'
import { ActualversusestimateProvider } from '../Context/Actualversusestimate'
import Estimates from '../pages/jobs/Estimater/Estimates'
import Newrfq from './RFQ/Newrfq'
import { NotificationProvider } from '../Context/Notifications'
import Shiplog from './shipping/Shiplog'




const Dashboard = () => {

const nav = useNavigate()
const {userInfo} = useContext(UserContext)

const [showchat, setShowchat] = useState(false)

const [allm, setAllm] = useState([])
//console.log(userInfo)

const {addNewChatMember} = useContext(ChatContext)


useEffect(() => {

  
 
},[userInfo])



/*
  const {ver} = useContext(UserContext)

  const location = useLocation();

  useEffect(() => {
    let version = Cookies.get('version')
    if(version !== "5" ){
  
      Cookies.set('version', '5')
      window.location.reload(false);
    }
  },[location]);


*/



  return (
    <>
    
    <Wrapper >
 
<Chat show={showchat} showchat={showchat} setShowchat={setShowchat}/>
<NotificationProvider>
      <POProvider >
   <PressProvider>

      <Header/>
      
       <Sidenav showchat={showchat} setShowchat={setShowchat}/>
      
      

<Routes>
 



          <Route path="/supplies" element={<Supplies />} />
          <Route path="admin" element={<Admin />} />
          <Route path="pos" element={<Pos />} />
          <Route path="pos/plsnew" element={<PLSNewPO />} />
          <Route path="pos/closedpo" element={<Closedpurchase />} />
          <Route path="vendors" element={<Vendors />} />
          <Route path="vendors/vendor/:id" element={<Vendor />} />
      
          <Route path="jobs" element={<Jobs />} />
          <Route path="mailing/eddm" element={<Eddm />} />
     <Route path="customers" element={<Customers />} />  
          <Route path="customers/customer/:id" element={<Customer />} />
          <Route path="customers/hgcustomer/:id" element={<HGCustomer />} />
          <Route path="inventory" element={<Inhouse />} />
          <Route path="/locations" element={<Locations />} />
          <Route path="inventory/paper/:id" element={<Paperitem />} />
          <Route path="inventory/allocated" element={<Allocated />} />
          <Route path="inventory/stock/:id" element={<Stockitem />} />
          <Route path="stock/:id" element={<Stockitem />} />
          <Route path="estimates" element={<Estimates />} />
          <Route path="estimate/:quote" element={<EstimateProvider><Estimater /></EstimateProvider>} />
     
          <Route path="rfq/new" element={<Newrfq />} />
          <Route path="pos/newpo/" element={<Newpo />} />
          <Route path="newrfq" element={<RFQ />} />
          <Route path="pos/cfpnewpo/" element={<CFPNewpo />} />
          <Route path="pos/cfppo/:po" element={<CFPPO />} />
          <Route path="pos/ponew/:id" element={<Ponew />} />
          <Route path="search/:term" element={<Search />} />
        
   
   <Route path="employees" element={<Employees />} />
   <Route path="acualvsestimate/:job" element={<Acualvsestimate />} />
        
<Route path="jobticket/job/:job" element={<JobTicket1 />} />
<Route path="jobticket/new/:job" element={<JobTicket1 />} />
<Route path="jobticket/new/" element={<JobTicket1 />} />
<Route path="jobticket/:job" element={<Ticketcheck />} />
<Route path="jobticket/versus" element={<Versus />} />
<Route path="*" element={<Main />} />
<Route path="employees/:id" element={<Employee />} />
{/* <Route path="quoteletter/:id" element={<Quoteletter />} /> */}
<Route path="jobticket/save/:job" element={<Savejob />} />
<Route path="jobticket/good/:job" element={<ActualversusestimateProvider><JobTicket /></ActualversusestimateProvider>} />
<Route path="schedules" element={<Schedules />} />
<Route path="elect" element={<Elections />} />
<Route path="email" element={<Email />} />
<Route path="shipping" element={<Shipping />} />
<Route path="whitesheet/:job" element={<Whitesheet />} />
<Route path="shiptickets/:job" element={<Shipticket />} />
<Route path="sitesettings" element={<Sitesettings />} />
<Route path='/daily' element={<Dailytest />} />
<Route path='/reports' element={<Reports />} />
<Route path='/quote/letter/:id' element={<Quoteletter />} />
<Route path='/csv' element={<Csv />} />
<Route path='/sales' element={<SalesDash />} />
<Route path='/sched' element={<NewSchedule />} />
<Route path='/test' element={<TEST />} />
<Route path='/cal' element={<Calendar />} />
<Route path='/shipreprint' element={<Shipreprint />} />
<Route path='/shiplog' element={<Shiplog />} />
        </Routes>



        </PressProvider>
        </POProvider>
        </NotificationProvider>
     


    </Wrapper>
    </>
  )
}

export default Dashboard



