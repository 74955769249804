import OrangeBar from '../../Assets/headerbarorange.png'
import orangeflag from '../../Assets/flagorange.png'
import { Button, Flex, Flexed, Img, Input, MainWrap, Wrap } from '../../utilities/Shared'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import InvPanel from './InvPanel';
import { useContext, useEffect, useState } from 'react';
import { IoMdAddCircle } from 'react-icons/io';
import axios from 'axios'
import Modal from '../../modals/Modal';
import Addinventory from './Addinventory';
import { useNavigate } from 'react-router-dom';
import Table from '../../utilities/Table';
import { inventoryColumns } from '../../utilities/Columns';
import { v4 as uuidv4 } from 'uuid';
import UserContext from '../../Context/UserContext';


const Inhouse = () => {

    const [search, setSearch] = useState("")
    const [inventorylist, setInventorylist] = useState()
    const [addview , setAddview] = useState(false)
    const [reset , setReset] = useState(false)
    const [reset2 , setReset2] = useState(false)
    const [newlist, setNewlist] = useState()
    const [newlist2, setNewlist2] = useState()
    const [zerolist, setZerolist] = useState()
    const [unassignedlist, setUnassignedlist] = useState()
    const [order, setOrder] = useState("ASC")  
 
const nav = useNavigate()


useEffect(() => {

    const getinventory = async() => {
      
   await axios.get('https://highgroundapi.herokuapp.com/inv/items')
  
    .then(res => {setNewlist(res.data);setNewlist2(res.data);getzero()})
    .catch(err => console.log(err))

    }
    getinventory()
    },[reset])

    const getzero = async() => {
      await axios.get('https://highgroundapi.herokuapp.com/inv/zero/items')
      .then(res => {setZerolist(res.data);getunassigned()})
      .catch(err => console.log(err))
      }

      const getunassigned = async() => {

        await axios.get('https://highgroundapi.herokuapp.com/inv/unassigned')
        //await axios.get('http://localhost:5003/inv/unassigned')
        .then(res => {setUnassignedlist(res.data);})
        .catch(err => console.log(err))
  
        }

const sortnum = (col => {
    if(order === "ASC"){
    const sorted = [...newlist].sort((a,b) =>
    parseInt(a[col]) > parseInt(b[col]) ? 1 : -1)
    setNewlist(sorted)
    setOrder("DESC")
    }
    if(order === "DESC"){
        const sorted = [...newlist].sort((a,b) =>
        parseInt(a[col]) < parseInt(b[col]) ? 1 : -1)
        setNewlist(sorted)
        setOrder("ASC")
        }
        })
        const sortit = (col => {
            if(order === "ASC"){
            const sorted = [...newlist].sort((a,b) =>
            a[col].toString().toLowerCase() > b[col].toString().toLowerCase() ? 1 : -1)
            setNewlist(sorted)
            setOrder("DESC")
            }
            
            if(order === "DESC"){
                const sorted = [...newlist].sort((a,b) =>
                a[col].toString().toLowerCase() < b[col].toString().toLowerCase() ? 1 : -1)
                setNewlist(sorted)
                setOrder("ASC")
                }
                })
        
                const changelist = (stocktype) => {
                  console.log(newlist)
                  let a
                  if(stocktype !== "All"){
                    a = newlist2.filter(filt => filt.type === stocktype)  
                    console.log(a)
                 setNewlist(a) 
                  }else{
                   setNewlist(newlist2) 
                }
                
                } 

  return (
  <MainWrap>

                  <Modal show={addview} header={`Add To Inventory`} onCancel={() => {setAddview(false)}} ><Addinventory  reset={reset} setReset={setReset} onCancel={() => {setAddview(false)}} /></Modal>
        <Flexed><Flex style={{whiteSpace:"nowrap",textAlign:"left"}} fontSize="calc(1.2rem + .4vw)"  padding="20px 0px" fontWeight="bold" color="#EA8D1F"
         >
         <Flexed>
          <Flex flex="0" fontSize="calc(1.2rem + .4vw)">Inventory</Flex>
          <Flex> <Img src={OrangeBar} style={{width:"100%",height:"24px"}}/></Flex>
          </Flexed> 
          <Flexed><Flex fontSize="calc(1.2rem + .4vw)" style={{opacity:"1",background:"var(--plsBlue)",height:"24px",position:"relative"}}><Img src={orangeflag} style={{width:"22px",zIndex:"20"}}/></Flex></Flexed>
          
         </Flex></Flexed>
         <Flexed margin="0px 0px 20px 0px"><Flex><Input type="text" value={search} placeholder="Search" onChange={e => setSearch(e.target.value.toLowerCase())} /></Flex>
      <Flex flex=".3" textAlign="right" ><IoMdAddCircle  style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)"}} onClick={e => setAddview(true)}/> </Flex>
      <Button style={{position:"absolute",right:"20vw",top:"63px"}} onClick={e => nav('/supplies')}>Stock</Button>
      <Button style={{position:"absolute",right:"10vw",top:"63px"}} onClick={e => nav('/locations')}>Locations</Button>
      </Flexed>
         <Tabs defaultIndex={localStorage.getItem('default01')}>
    <TabList style={{marginBottom:"40px"}} >
    <Tab onClick={e => {localStorage.setItem('default01',0);localStorage.setItem('company','PLS')}}>PLS Print</Tab>
    <Tab onClick={e => {localStorage.setItem('default01',1);localStorage.setItem('company','CFP')}}>CFPp</Tab>
    <Tab onClick={e => {localStorage.setItem('default01',2);localStorage.setItem('company','MPS')}} >MPS</Tab>
   
  </TabList>

  <TabPanel >

<Tabs>
    <TabList>
    <Tab >Current Inventory - {newlist2?.length}</Tab>
    <Tab >0'ed Inventory - {zerolist?.length}</Tab>
    <Tab >Unassigned Inventory - {unassignedlist?.length}</Tab>
    </TabList>
    <TabPanel > <InvPanel setSearch={setSearch} changelist={changelist} search={search} list={newlist} newlist={newlist} setNewlist={setNewlist} order={order} setOrder={setOrder} sortnum={sortnum} sortit={sortit} /><br /><br /><br /><br /></TabPanel>
  <TabPanel > <InvPanel setSearch={setSearch} search={search} list={zerolist} newlist={zerolist} setZerolist={setZerolist} order={order} setOrder={setOrder} sortnum={sortnum} sortit={sortit}/></TabPanel>
  <TabPanel ><InvPanel setSearch={setSearch} search={search} list={unassignedlist} newlist={unassignedlist} setUnassignedlist={setUnassignedlist} order={order} setOrder={setOrder} sortnum={sortnum} sortit={sortit}/></TabPanel>

</Tabs>

 
  </TabPanel>
{/*/////////////////////////////////////////////////////CFP*/}
  <TabPanel>
  <InvPanel setSearch={setSearch} search={search}/>
  </TabPanel>
{/*/////////////////////////////////////////////////////MPS*/}
  <TabPanel>
  <InvPanel setSearch={setSearch} search={search}/>
  </TabPanel>
 
  </Tabs>
  </MainWrap>
  )
}

export default Inhouse