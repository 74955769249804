
import { createContext, useState, useEffect } from "react"
import axios from 'axios'
import { ToastContainer, toast,Slide, Zoom, Flip, Bounce,cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import io from 'socket.io-client'



export const UserContext = createContext();

export const UserProvider = ({children}) => {
const [userInfo, setUserinfo] = useState({});
const [empid, setEmpid] = useState('');
const [reset, setReset] = useState(false);
const [spin, setSpin] = useState(false)
const [userName, setUserName] = useState('')
const [employees, setEmployees] = useState([])
const [customers, setCustomers] = useState([])




useEffect(() => {

  if(sessionStorage.getItem('trackuser')){

    resetUser(sessionStorage.getItem('trackuser'))
   // const getEmployee = async() => {
    //  let rr = {"id":sessionStorage.getItem('trackuser')}
    /*  await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/employee/getemployee',rr)
      .then(res => {
  console.log(res.data)
        setUserinfo({...res.data,newname:res.data.preferredName+" "+res.data.lastName.charAt(0)})  
      })
  
      .catch(err => console.log(err))
    }
    getEmployee()
    */
  }


  
  const getemployees = async() => {
   await axios.get(`https://highgroundapi.herokuapp.com/employee/active`)
   // await axios.get(`http://localhost:5003/employee/active`)
.then(res => {setEmployees(res.data)})
.catch(error => { console.log(error)})
}
  const getcustomers = async() => {
  await axios.get(`https://highgroundapi.herokuapp.com/customers/all`)
   // await axios.get(`http://localhost:5003/customers/all`)
.then(res => {setCustomers(res.data)})
.catch(error => { console.log(error)})
}
getemployees()
getcustomers()
},[spin])



const updateEmployeeInfo = async() => {
 // axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/employee/update/employee',empinfo)
 // .then(res => toast.success('Employee Updated'))
 // .catch(err => console.log(err))
 // console.log(empinfo)
}

const LoginCheck = async(info) => {

if(!info?.username ){
  return toast.error('Username can not be empty')
}
if(!info?.password ){
  return toast.error('Password can not be empty')
  }
const r = {
  "username":info.username,
  "password":info.password,
}

await axios.post('https://highgroundapi.herokuapp.com/employee/login',r)
.then(res => {
  setUserinfo({...res.data.userinfo,fullName:res.data.userinfo?.preferredName+" "+res.data?.userinfo?.lastName?.charAt(0)});
sessionStorage.setItem('trackuser',res.data.userinfo._id);
 sessionStorage.setItem('fullName',res.data.userinfo?.preferredName+" "+res.data?.userinfo?.lastName?.charAt(0));
 sessionStorage.setItem('page',res.data?.userinfo?.departments[0]?.label);
 sessionStorage.setItem('eid',res.data?.userinfo?.empId);
 let userName = sessionStorage.getItem('fullName')

 // sessionStorage.setItem('access',res.data.userinfo.access);
 // sessionStorage.setItem('empId',res.data.userinfo.empId);
})
.catch(err => {console.log(err);toast.error(err.response?.data?.message)})

}

const resetUser = async(id) => {
  const t = await axios.get(`https://highgroundapi.herokuapp.com/employee/item/${id}`)
  .then(res => {
    setUserinfo({...res.data,fullName:res.data?.preferredName+" "+res.data?.lastName?.charAt(0)});
    sessionStorage.setItem('trackuser',res.data._id);
    sessionStorage.setItem('page',res.data?.departments[0]?.label);
    sessionStorage.setItem('eid',res.data?.empId);                     
 sessionStorage.setItem('fullName',res.data?.preferredName+" "+res.data?.lastName?.charAt(0));

  })
  .catch(err => console.log(err))

}


const logout = () => {
  setUserinfo({})
  sessionStorage.removeItem('trackuser')
  sessionStorage.removeItem('fullName')
  sessionStorage.removeItem('auth')
  sessionStorage.removeItem('page');
}


 





  //setUserinfo({...userInfo,logged:true})
 // sessionStorage.setItem("logged",true)
 // setUserinfo({
 //   logged:sessionStorage.getItem("logged"),
     // name:info.firstName+" "+info.lastName.charAt(0),
     // auth:info.auth
   // })

//await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/employee/login',info)
//.then(res => {console.log(res)
 //const info = res.data.userinfo;

////ocalStorage.setItem('logged', true);
///localStorage.setItem('name', info.firstName+" "+info.lastName.charAt(0));
//toast.success(`Welcome Back ${info.firstName}`)
//})
//.catch(err => {console.log(err);toast.error(err.response.data.message,{pauseOnHover: false})})





//setUserinfo({...userInfo,logged:true,name:"User"})







  return (
    <UserContext.Provider value={{userInfo,LoginCheck,setUserinfo,logout,spin,setSpin,resetUser,employees,customers}}>
         <ToastContainer position="top-center" theme="dark"  autoClose="500" hideProgressBar={true} transition={Flip} />
        {children}
    </UserContext.Provider>
  )
}

export default UserContext 