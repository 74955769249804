import axios from 'axios'
import React, { useEffect,useContext } from 'react'
import { useState } from 'react'
import {useParams,useNavigate} from 'react-router-dom'
import { Button, Flex, Flexed, Input, MainWrap, Scroll, Selectbox, Textarea, currency } from '../../utilities/Shared'
import Dropdown from '../../utilities/Dropdown'
import { addinventorylist, adjustinventorylist, removeinventorylist } from '../../utilities/Lists'
import Addinventory from './Addinventory'
import Moment from 'react-moment'
import { toast } from 'react-toastify'
import Select from '../../utilities/Select'
import { locationareas } from '../../utilities/Lists'
import UserContext from '../../Context/UserContext'
import ForJobs from './ForJobs';
import Modal from '../../modals/Modal'
import ChatContext from '../../Context/ChatContext'


const Paperitem = () => {

  const { socket,users,sendsinglemessage,newmessagecount}  = useContext(ChatContext)


  console.log(socket)
    const resetnewinfo = {
date:"",
differ:"",
isnow:"",
po:"",
was:"",
job:"",
quantity:"",
reason:"",
bin:""
    }

    const valueset = {
        id:"",
        name:""
    }

const [item, setItem] = useState({})
const [sameitem, setSameitem] = useState(null)
const [currentselection, setCurrentselection] = useState(0)
const [value , setValue] = useState({})

const [newinfo, setNewinfo] = useState({})
const [addview , setAddview] = useState(false)
const [reset , setReset] = useState(false)
const [bins, setBins] = useState({})
const [bin, setBin] = useState({})

const [cwt, setCwt] = useState()
const [mwt, setMwt] = useState()
const [avail, setAvail] = useState(0)
const [used, setUsed] = useState()
const [locationarea, setLocationarea] = useState({id:0,name:"Paper Areas"})
const [binslist, setBinslist] = useState([])
const [theone, setTheone] = useState({})
const [papers, setPapers] = useState([])
const [paper, setPaper] = useState({})
const [forjobslist, setForjobslist] = useState([])
const nav = useNavigate()
const [current, setCurrent] = useState()
const [autos, setAutos] = useState()
const [forjobsshow, setForjobsshow] = useState(false)
    const param = useParams()

    const {userInfo} = useContext(UserContext)

const reduceit = (arr) => {
   arr.reduce(function(acc, cur, i) {
        acc[i] = cur;
        return acc;
      }, {});
}
   


    useEffect(() => {
        
        let  o
        let a
        let b
const getitem = async() => {
    let t
    await axios.get(`https://highgroundapi.herokuapp.com/inv/item/${param.id}`)
   // await axios.get(`http://localhost:5003/inv/item/${param.id}`)
.then(res => {setItem(res.data);
    getsame(res.data.paperid,res.data.invid);
    setForjobslist(res.data.forjobs)
    setBins({...bins,name:res.data.bin});
    setBin({...bin,name:res.data.bin});
    setLocationarea({...locationarea,name:res.data.location});
let a = res.data.po?.items?.filter(item => item.itemid === res.data.poitemid);
setTheone(a[0]);
getpapers(res.data.paperid);


})
.catch(err => console.log(err))
}
const getsame = async(paperid,invid) => {
    await axios.get(`https://highgroundapi.herokuapp.com/inv/same/${paperid}`)
    //await axios.get(`http://localhost:5003/inv/same/${paperid}`)
    .then(res => {
        setSameitem(res.data.filter(item => item.invid !== invid));
      //  getbins()
    })
    .catch(err => console.log(err))
    } 
    getitem()
 
    },[reset])



    const forjobscheck = () => {
  
        let k = []
        let t = 0
   forjobslist?.map(yu => {t += parseFloat(yu.quantity)})

   return  item.currentInventory - t
    }
    //forjobscheck()




    const getpapers= async(paperid) => {
        await axios.get('https://highgroundapi.herokuapp.com/paper/all')
       // await axios.get('http://localhost:5003/paper/all')
        
        .then(res => {
            setPaper(res.data.filter(paper => paper.stockid === paperid))})
        .catch(err => console.log(err))
    }


    const getbins = async() => {
        await  axios.post(`https://highgroundapi.herokuapp.com/inv/test`)
       // await  axios.post(` http://localhost:5003/inv/test`)
       
        .then(res => {setBinslist(res.data)})
        .catch(err => console.log(err))
        }



        const getcost = () => {
            
         let a
         let b
        switch(item?.priceby){
            case "CWT":
        a = (item.cwt * item.currentInventory) /100
          break;
          case "Per M":
              a = (item.currentInventory / 1000) * item.perm
          break;
          case "Roll LBS":
            a = (item.cwt * item.currentInventory) / 100
          break;
          case "Each":
          case "Case":
          case "Box":
              a =item.currentInventory  * item.perm
            break;
            case "Lot":
                    a = item.unitprice  * 1
              break;
          default:
             a = null
            break;
          }
      
       
return a
        }

        useEffect(() => {
            setCwt(theone?.type === "Roll Stock" ? theone?.unitprice : paper?.cwt)
            setMwt(theone?.type !== "Roll Stock" ? theone?.unitprice : "")
            },[paper])




    const additem = () => {     
newinfo.reason = value.name
newinfo.was = item.currentInventory > 0 ? item.currentInventory : 0
newinfo.isnow =  parseInt(newinfo.was) + parseInt(newinfo.quantity)
newinfo.differ = "+ "+newinfo.quantity
newinfo.date = new Date()
newinfo.bin = item.bin
newinfo.who = userInfo.preferredName+" "+userInfo.lastName.substr(0,1)
console.log(newinfo)

const info = {
    "id":param.id,
    "newhistory":newinfo
}
       const y = async() => {
       await axios.post(`https://highgroundapi.herokuapp.com/inv/add/to/history`,info)
       // await axios.post(`http://localhost:5003/inv/add/to/history`,info)
        
        .then(res => {setNewinfo(resetnewinfo);toast.success('History Update');setValue(valueset);setnewquan()})
        .catch(err => console.log(err))
       }
      y()
  
    }
   
    const setnewquan = async() => {
        const newstuff = {
            id:param.id,
            quan:newinfo.isnow,
            forjobs:forjobslist
        }
        await axios.post(`https://highgroundapi.herokuapp.com/inv/replace`,newstuff)
        //await axios.post(`http://localhost:5003/inv/replace`,newstuff)
        
        .then(res => {console.log(res.data);setReset(!reset)})
        .catch(err => console.log(err))

      
    }


    const removeitem = () => {     
        newinfo.reason = value?.name
        newinfo.was = item.currentInventory
        newinfo.isnow = (parseInt(newinfo.was) - parseInt(newinfo.quantity))
        newinfo.differ = "- "+newinfo.quantity
        newinfo.date = new Date()
        newinfo.bin = item.bin
        newinfo.who = userInfo.preferredName+" "+userInfo.lastName.substr(0,1)
        const info = {
            "id":param.id,
            "newhistory":newinfo
        }
  
               const y = async() => {
                await axios.post(`https://highgroundapi.herokuapp.com/inv/add/to/history`,info)
                  // await axios.post(`http://localhost:5003/inv/add/to/history`,info)
                .then(res => {toast.success('History Update');setNewinfo(resetnewinfo);setValue(valueset);setnewquan()})
                .catch(err => console.log(err))
               }
          y()
            }



            console.log(forjobslist)

            const autoremove = (e,thing) => {
              if(e.target.value === "Job Canceled"){
              
const newjobs = forjobslist.filter(filter => filter.job !== thing.job)
const info = {
  id:item._id,
  forjobs:newjobs
}
console.log(info)  
const r = async() => {
  await axios.post(`https://highgroundapi.herokuapp.com/inv/update/jobs`,info)
  //await axios.post(`http://localhost:5003/inv/update/jobs`,info)
.then(res => {console.log(res.data);setForjobslist(res.data.forjobs);setAutos("");toast.success('Job Canceled');setReset(!reset)})
.catch(err => console.log(err))
}
//toast.success('History Update');setNewinfo(resetnewinfo);setValue(valueset);setnewautoquan( newinfo.isnow,thing)
r()
              }
              if(e.target.value === "Remove For Job"){
                newinfo.reason = "Removed for job "+thing.job
                newinfo.was = item.currentInventory
                newinfo.isnow = (parseInt(newinfo.was) - parseInt(thing.quantity))
                newinfo.differ = "- "+thing.quantity
                newinfo.date = new Date()
                newinfo.bin = item.bin
                newinfo.who = userInfo.preferredName+" "+userInfo.lastName.substr(0,1)
                const info = {
                    "id":param.id,
                    "newhistory":newinfo
                }
              
                       const y = async() => {
                        await axios.post(`https://highgroundapi.herokuapp.com/inv/add/to/history`,info)
                          // await axios.post(`http://localhost:5003/inv/add/to/history`,info)
                        .then(res => {toast.success('History Update');setNewinfo(resetnewinfo);setValue(valueset);setnewautoquan( newinfo.isnow,thing)})
                        .catch(err => console.log(err))
                       }
                 y()
                      }

                      if(e.target.value === "Actual Usage"){
                        if(used > ""){
                          newinfo.reason = "Removed for job "+thing.job
                          newinfo.was = item.currentInventory
                          newinfo.isnow = (parseInt(newinfo.was) - parseInt(used))
                          newinfo.differ = "- "+used
                          newinfo.date = new Date()
                          newinfo.bin = item.bin
                          newinfo.who = userInfo.preferredName+" "+userInfo.lastName.substr(0,1)
                          const info = {
                              "id":param.id,
                              "newhistory":newinfo
                          }
                        console.log(info)
                                 const y = async() => {
                                  await axios.post(`https://highgroundapi.herokuapp.com/inv/add/to/history`,info)
                                    // await axios.post(`http://localhost:5003/inv/add/to/history`,info)
                                  .then(res => {toast.success('History Update');setNewinfo(resetnewinfo);setValue(valueset);setnewautoquan( newinfo.isnow,thing)})
                                  .catch(err => console.log(err))
                                 }
                          y()
                        }
                         
                                
                      }

            }




            const setnewautoquan = async(isnow,thing) => {

            
           
               
                const t = forjobslist?.filter(filtered => filtered.job !== thing.job)
                const newstuff = {
                    id:param.id,
                    quan:isnow,
                    bin: item.bin,
                    forjobs:t
                }

              await axios.post(`https://highgroundapi.herokuapp.com/inv/replace`,newstuff)
                //await axios.post(`http://localhost:5003/inv/replace`,newstuff)
                
                .then(res => {console.log(res.data);setAutos("");setReset(!reset)})
                .catch(err => console.log(err))
             
         
            }
                    const updatebin = async() => {
                        const tyu = {
                            id:param.id,
                            bin: bins.name,
                            location:locationarea.name
                        }
                  await axios.post(`https://highgroundapi.herokuapp.com/inv/update/bin/location`,tyu)
                      // await axios.post(`http://localhost:5003/inv/update/bin/location`,tyu)
                        .then(res => {
                            const u = {
                               reason:"Binned location changed to "+bins.name,
                               was:item.currentInventory,
                               isnow:item.currentInventory,
                               differ:0,
                               date:new Date(),
                               who:userInfo.preferredName+" "+userInfo.lastName.substr(0,1)
                            }
                            const info = {
                                "id":param.id,
                                "newhistory":u,
                              
                            }
                            console.log(info)
                                   const y = async() => {
                                    await axios.post(`https://highgroundapi.herokuapp.com/inv/add/to/history`,info)
                                    .then(res => {toast.success('History Update');setNewinfo(resetnewinfo);setValue(valueset);setReset(!reset)})
                                    .catch(err => console.log(err))
                                   }
                                  y()
                        })
                        .catch(err => console.log(err))
                       }
     
const savecost =  async() => {
let a = {
    id:item._id,
    cwt:item.cwt,
    perm:item.perm,
    size:item.size,
    name:item.name,
    priceby:item.priceby,
    forjobs:forjobslist,
    type:item.type,
    category:item.category
} 


console.log(a)
  await axios.post('https://highgroundapi.herokuapp.com/inv/update/cost',a)
 //await axios.post('http://localhost:5003/inv/update/cost',a)
   .then(res => {console.log(res.data);setReset(!reset);toast.success('Item Updated') })
  .catch(err => console.log(err))


}




const updatehistory = (reason,was,isnow,differ,Bindery) => {

    newinfo.reason = reason
    newinfo.was = was
    newinfo.isnow = isnow
    newinfo.differ = differ
    newinfo.date = new Date()
    newinfo.bin = bin
    newinfo.who = userInfo.preferredName+" "+userInfo.lastName.substr(0,1)
    const info = {
        "id":param.id,
        "newhistory":newinfo
    }
  
           const y = async() => {
            await axios.post(`https://highgroundapi.herokuapp.com/inv/add/to/history`,info)
              // await axios.post(`http://localhost:5003/inv/add/to/history`,info)
            .then(res => {toast.success('History Update');savecost()})
            .catch(err => console.log(err))
           }
 y()
}

  
console.log(item)

  return (
    <MainWrap>
  <Modal  show={forjobsshow} header={`Add jobs for this item`} onCancel={() => {setForjobsshow(false)}} ><ForJobs updatehistory={updatehistory} onCancel={() => {setForjobsshow(false)}}  reset={reset} setReset={setReset}  selected={item} setSelected={setItem}/></Modal>
        <Flexed alignItems="flex-start" padding= "30px 0px 0px 0px" fontSize="calc(.8rem + .4vw)">
            <Flex flex="3">

                <Textarea style={{fontSize:"calc(1.5rem + .4vw)",width:"100%"}} type="text" value={item.name} onChange={e => setItem({...item,name:e.target.value})}></Textarea>
                </Flex>
            <Flex fontSize="calc(1.1rem + .4vw)" textAlign="center" ><u style={{fontSize:"calc(1.1rem + .4vw)",fontWeight:"bold"}}>Available</u><br /><span style={{color:forjobscheck() <= 0 && "red",fontWeight:forjobscheck() <= 0 ? "bold" : "normal",fontSize:"calc(1.1rem + .4vw)"}}>{forjobscheck()}</span></Flex>
            <Flex fontSize="calc(1.1rem + .4vw)" textAlign="center"><u style={{fontSize:"calc(1.1rem + .4vw)",fontWeight:"bold"}}>In Stock</u><br />{item.currentInventory}</Flex>
            <Flex fontSize="calc(1.1rem + .4vw)" textAlign="center"><u style={{fontSize:"calc(1.1rem + .4vw)",fontWeight:"bold"}}>Current Value</u><br />${currency(getcost())}</Flex>
        </Flexed>
        <Flexed alignItems="flex-start" padding= "30px 0px 0px 0px" fontSize="calc(.8rem + .4vw)" gap="20px">
                    <Flex fontSize="calc(1.1rem + .4vw)">Size:<br /> <Input type="text" value={item.size} onChange={e => setItem({...item,size:e.target.value})} /></Flex>
                    <Flex fontSize="calc(1.1rem + .4vw)">Inv Id:<br />{item.invid}</Flex>
                    <Flex fontSize="calc(1.1rem + .4vw)">Paper Id:<br />{item.paperid}</Flex>
                    <Flex fontSize="calc(.8rem + .4vw)">Bin<br /><Select setValue={setBins} listid="bins" value={bins.name} list={binslist?.map(item => ({name:item?._id}))}/></Flex>
                
                    <Flex fontSize="calc(.8rem + .4vw)" >Location<br /><Select setValue={setLocationarea} listid="locats" value={locationarea.name} list={locationareas?.map(item => ({id:item?.id,name:item.name}))}/><br /><Button  type="button" onClick={updatebin} style={{float:"right"}}>Update Bin</Button></Flex>
                  
                    <Flex fontSize="calc(.9rem + .4vw)">
                     Type:<br />
                     <Selectbox value={item.type} onChange={e => setItem({...item,type:e.target.value})}>
    <option value=""  >Select Kind</option>
        <option value="Offset / Text / Book">Offset / Text / Book -- (950)</option>
        <option value="Cover">Cover -- (520)</option>
        <option value="Bond / Writing">Bond / Writing -- (374)</option>
        <option value="Bristol">Bristol -- (641)</option>
        <option value="Index">Index -- (778)</option>
        <option value="Board / Tag">Board / Tag -- (864)</option>
        <option value="Label">Label -- (950)</option>
        <option value="Envelopes">Envelopes -- (374)</option>
        <option value="Roll">Roll -- (950)</option>
       
        </Selectbox>
              
                      </Flex>
                      <Flex fontSize="calc(.9rem + .4vw)">
                    Category:<br />
                    <Selectbox value={item.category} onChange={e => setItem({...item,category:e.target.value})} >
        <option value="Sheet Stock">Sheet Stock</option>
        <option value="Roll Stock">Roll Stock</option>
        <option value="Envelope">Envelopes</option>
       
        </Selectbox>
              
                      </Flex>
                </Flexed>


<Flexed padding="20px 0px 0px 0px">
<Flex></Flex>
  <Flex>CWT:&nbsp;<Input style={{height:"30px"}} value={item?.cwt} onChange={e => setItem({...item,cwt:e.target.value})} width="70%" height="20px"/></Flex>
  <Flex >Price By:&nbsp;  <select style={{height:"30px",width:"70%"}} value={item?.priceby} onChange={e => setItem({...item,priceby:e.target.value})}  >
                              <option value=""></option>
                                <option value="CWT">CWT</option>
                                <option value="Per M">Per M</option>
                                <option value="Each">Each</option>
                                <option value="Roll LBS">Roll LBS</option>
                                <option value="Case">Case</option>
                                <option value="Box">Box</option>
                              </select>
                              </Flex>
  <Flex>Unit Price:&nbsp;<Input style={{height:"30px"}} value={item?.perm} onChange={e => setItem({...item,perm:e.target.value})} width="70%" height="20px"/></Flex>
  <Flex>Value:&nbsp;<span style={{paddingLeft:"10px",fontSize:"calc(1.2rem + .4vw)"}}>
${currency(getcost())}</span></Flex>

</Flexed>
       <Flexed alignItems="flex-start" padding= "30px 0px 20px 0px" fontSize="calc(.8rem + .4vw)">
        <Flex textAlign="center" onClick={e => setCurrentselection(0)}><Button onClick={e => setValue(valueset)}>Add</Button></Flex>
      
    
        <Flex textAlign="center"><Button onClick={e => setValue(valueset)}>Delete</Button></Flex>
        
        <Flex textAlign="center" onClick={e => setCurrentselection(1)}><Button onClick={e => setValue(valueset)}>Remove</Button></Flex>
        <Flex textAlign="center" ><Button onClick={e => setForjobsshow(true)} >Allocate</Button></Flex>
        <Flex flex="2"></Flex>
        <Flex textAlign="right"><Button onClick={e => savecost()}>Save</Button></Flex>
       </Flexed >
   
     {currentselection === 0 &&   <Flexed gap="30px" alignItems="center">
        <Flex flex="2">Reason for Adding<br /><Select setValue={setValue} listid="reason" value={value.name} list={addinventorylist?.map(item => ({name:item?.name,id:item?.id}))} required/></Flex>
        <Flex>PO<br /><Input value={newinfo?.po} onChange={e => setNewinfo({...newinfo,po:e.target.value})}/></Flex>
        <Flex>Job<br /><Input value={newinfo?.job} onChange={e => setNewinfo({...newinfo,job:e.target.value})}/></Flex>
        <Flex >Quantity<br /><Input value={newinfo?.quantity}  onChange={e => setNewinfo({...newinfo,quantity:e.target.value})}/></Flex>

        <Flex wordBreak="normal">{newinfo.quantity > 0 && value.name > "" && <Button onClick={additem}>Add To Inventory</Button>}</Flex>
       </Flexed>} 
       {currentselection === 1 &&   <Flexed gap="30px" alignItems="center">
       <Flex flex="2">Reason for Removing<br /><Select setValue={setValue} listid="reason" value={value.name} list={removeinventorylist?.map(item => ({name:item?.name,id:item?.id}))}/></Flex>
        <Flex>PO<br /><Input value={newinfo?.po} onChange={e => setNewinfo({...newinfo,po:e.target.value})}/></Flex>
        <Flex>Job<br /><Input value={newinfo?.job} onChange={e => setNewinfo({...newinfo,job:e.target.value})}/></Flex>
        <Flex >Quantity<br /><Input value={newinfo?.quantity}  onChange={e => setNewinfo({...newinfo,quantity:e.target.value})}/></Flex>
    
        <Flex wordBreak="normal">{newinfo.quantity > 0 && value.name > "" && <Button onClick={removeitem}>Remove From  Inventory</Button>}</Flex>
       </Flexed>}  
      
     
<hr style={{marginTop:"20px"}}/>
<Flexed>
<Flex flex="2">
<p style={{fontSize:"calc(.6rem + .4vw)",fontWeight:"bold",padding:"10px 0px"}}><u >Item History</u></p>
<Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white",marginBottom:"5px"}}>
<Flex flex=".8">Who</Flex>
     <Flex flex="4">Adjustment Reason</Flex>
     <Flex>Date</Flex>
     <Flex>Was</Flex>
     <Flex>Now</Flex>
     <Flex>Difference</Flex>
        <Flex>PO</Flex>
        <Flex>Job</Flex>
        <Flex>Cost</Flex>
  </Flexed>
  <Scroll height="200px">
{
    item.history?.map(ite => {
     return   <Flexed>
         <Flex flex=".8">{ite.who}</Flex>
        <Flex flex="4">{ite.reason}</Flex>
        <Flex ><Moment format="MM/DD/YYYY">{ite.stamp || ite.date}</Moment></Flex>
        <Flex>{ite.was}</Flex>
        <Flex>{ite.isnow}</Flex>
        <Flex>{ite.differ}</Flex>
        <Flex>{ite.po}</Flex>
           <Flex>{ite.job}</Flex>
           <Flex>{ite.cost}</Flex>
     </Flexed>
    }).reverse()
}
</Scroll>
</Flex>
<Flex>
<p style={{fontSize:"calc(.6rem + .4vw)",fontWeight:"bold",padding:"10px 0px"}}><u >Assigned Stock</u></p>
<Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white",marginBottom:"5px"}}>
<Flex flex=".8">Job</Flex>
     <Flex >Quantity</Flex>
     <Flex>Used</Flex>
     <Flex flex="2"></Flex>
  </Flexed>
  <Scroll height="200px">
{

   forjobslist?.map(ite => {
     return   <Flexed className=" normalrow hand" padding="4px" alignItems="center">
         <Flex flex=".8">{ite.job}</Flex>
        <Flex flex="1">{ite.quantity}</Flex>
        <Flex flex="1"><Input type="text"  onChange={e => setUsed(e.target.value)}/></Flex>
       
        <Flex flex="2"><select style={{fontSize:"1rem",width:"100%"}} value={autos} onChange={e => autoremove(e,ite)}>
        <option value="">Select Process</option>
            <option value="Remove For Job">Remove For Job</option>
            <option value="Job Canceled">Job Canceled</option>
            <option value="Actual Usage">Actual Usage</option>
            </select></Flex>
     </Flexed>
    }).reverse()
}
</Scroll>
</Flex>
</Flexed>


 
       <hr />
       <p style={{fontSize:"calc(.6rem + .4vw)",fontWeight:"bold",padding:"20px 0px"}}><u >Related Stocks</u></p>
{sameitem?.length === 0 ? <p>No Related Items Could Be Found</p> : <Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white",marginBottom:"5px"}}>
     <Flex>Inv Id</Flex>
     <Flex flex="2">Description</Flex>
        <Flex>Paper Id</Flex>
        <Flex>Size</Flex>
        <Flex>In Stock</Flex>
        <Flex>Bin</Flex>
  </Flexed>}
{sameitem?.filter(filtered => filtered.currentInventory > 0).map(item => {
  return  <Flexed  onClick={e => nav(`/inventory/paper/${item._id}`)}>
     <Flex>{item.invid}</Flex>
     <Flex flex="2">{item.name}</Flex>
        <Flex>{item.paperid}</Flex>
        <Flex>{item.size}</Flex>
        <Flex>{item.currentInventory}</Flex>
        <Flex>{item.bin}</Flex>
  </Flexed>
} )
}     
<br /><br />
    </MainWrap>
  )
}

export default Paperitem