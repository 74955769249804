import React, { useEffect, useState } from 'react'
import { Datepick, Flex, Flexed, Input, MainWrap, Scroll } from '../../utilities/Shared'
import axios from 'axios'
import Collapse from '../../utilities/Collapse'
import Moment from 'react-moment'
import { IoMdAddCircle } from 'react-icons/io'
import { sortdate, sortit, sortnum, sortshippingcustomer, sortshippingdescription } from '../../utilities/Sort'
import Modal from '../../modals/Modal'
import { RiDatabase2Fill } from 'react-icons/ri'
import Shipreprint from '../../Printing/Shipreprint'
import { useNavigate } from 'react-router-dom'
const moment = require('moment');

const Shiplog = () => {


const [list, setList] = useState([])
const [newlist, setNewlist] = useState([])
const[order, setOrder] = useState("ASC")
const[work, setWork] = useState(false)
const[start, setStart] = useState(null)
const[end, setEnd] = useState(null)
const[reset, setReset] = useState(false)
const[search, setSearch] = useState('')

const nav = useNavigate()

useEffect(() => {
    const day = new Date().getDay()
    let newday
    let newendday
    console.log(day)
    const d = new Date();
    const e = new Date();
    if(day === 1) {
        console.log('yes')
        newday = new Date(d.setDate(d.getDate() - 3))
        newendday = new Date(e.setDate(e.getDate() - 3)) 
    }else{
        newday = new Date(d.setDate(d.getDate() - 1))
        newendday = new Date(e.setDate(e.getDate() - 1))
    }
    console.log(newday)
    const getshippeditems = async() => {
        let nk =[]
    await axios.get('https://highgroundapi.herokuapp.com/ship/allshipped')
         //await axios.get('http://localhost:5003/ship/allshipped')
        .then(res => {
            console.log(res.data)
          res.data.map((item,index) => {
           // new Date(gg[0]?.items[0].shipdate)
      let tempnewdatee =item?.dateshipped !== null ? new Date(item?.dateshipped) : new Date(item.createdAt)
    
let y = {
    ...item,
    tempordered:gettotalordered(item?.items),
    tempsent:gettotalsent(item?.items),
    tempcustomer:item.customerid.name,
    tempdescription:item.job.jobname,
    tempdate:item?.dateshipped,
    tempnewdate:tempnewdatee,
   

}
nk.push(y)
          })  
            
            setList(nk)
        })
        .catch(err => console.log(err))
    }
        //let tt = new Date(newday.setDate(newday.getDate() - 4))
        newday.setHours(0, 0,0, 0);
        newendday.setHours(23, 0, 0, 0);
        console.log(newday)
    setStart(newday)
    setEnd(newendday)
        getshippeditems()
      //  listsort()
},[])




useEffect(() => {
    console.log(list)
    console.log(start)
    console.log(end)
   
    setnewlist() 
},[reset,start,end,list])

const setnewlist = () => {
    let gg
    gg = list?.filter(filt => filt.tempnewdate.getTime() >= start.getTime() && filt.tempnewdate.getTime() <= end.getTime())  
console.log(gg)
setNewlist(gg)
}


const gettotalordered = (item) => {
let r = 0
item.map(it => {
  r += parseInt(it.requested)
})
return r
}
const gettotalsent = (item) => {
    let r = 0
    item.map(it => {
      r += parseInt(it.sending)
    })
    return r
    }



    const changestartdate = (date) => {

let tempnewdatee =new Date(date)
tempnewdatee.setHours(0, 0,0, 0)
console.log(tempnewdatee)
setStart(tempnewdatee)
setReset(!reset)
    }
    
    const changeenddate = (date) => {

        let tempnewdatee =new Date(date)
        tempnewdatee.setHours(23, 0, 0, 0)
        console.log(tempnewdatee)
        setEnd(tempnewdatee)
        setReset(!reset)
            }
//     const date1 = new Date('2024-08-29T10:00:00')
//    date1.setHours(0, 0, 0, 0);
//     const date2 = new Date('2024-08-29T18:30:00')
//     date2.setHours(0, 0, 0, 0);
//     // date1;
//     // date2.setHours(0, 0, 0, 0);
//     if (date1.getTime() === date2.getTime()) {
//         console.log('Dates are the same');
//   } else {
//         console.log('Dates are different');
//   }
useEffect(() => {
if(search === ""){
setReset(!reset)
}
},[search])



const searchlist = (e) => {
    if (e.key === "Enter") {

if(search > "" || search > null){
        console.log('yes')
       let gg = list?.filter(filt => {return search === '' ? filt : filt.jobnum.includes(search) || filt.shipid.includes(search) || filt.customerid.name.toLowerCase().includes(search.toLowerCase()) || filt.job.jobname.toLowerCase().includes(search.toLowerCase())}) 
      if(gg.length > 0){
        setNewlist(gg) 
      
      }
     
    }else{
        setReset(!reset)
    }
    }
    
}



console.log(list)

  return (

  <MainWrap padding="110px 30px 0px 0px">
    <Modal show={work} >Working...</Modal>

    <Flexed margin="0px 0px 20px 0px">
        <Flex>Search<br /><Input type="text" value={search} placeholder="Search" onKeyDown={e => searchlist(e)} onChange={e => setSearch(e.target.value)} /></Flex>
        <Flex></Flex>
        <Flex></Flex>
        <Flex>Date Start<br /><Datepick selected={start} onChange={date => changestartdate(date)}/></Flex>
        <Flex>Date End<br /><Datepick  selected={end} onChange={date => changeenddate(date)}/></Flex>
    </Flexed>

<Flexed borderBottom="1px solid black" fontSize="calc(1.2rem + .2vw)">
<Flex flex=".3" textAlign="right" className="hand"></Flex>
<Flex flex=".7" onClick={e => sortnum('jobnum',order,list,setList,setOrder)} className="hand">Job #</Flex>
<Flex flex=".7" onClick={e => sortnum('shipid',order,list,setList,setOrder)} className="hand">Ship Id</Flex>
<Flex flex="3" onClick={e => sortit('tempcustomer',order,list,setList,setOrder)} className="hand">Customer</Flex>

    <Flex flex="3" onClick={e => sortit('tempdescription',order,list,setList,setOrder)} className="hand">Description</Flex>
    <Flex onClick={e => sortnum('tempordered',order,list,setList,setOrder)} className="hand">Qty Ordered</Flex>
    <Flex onClick={e => sortnum('tempsent',order,list,setList,setOrder)} className="hand">Qty Delivered</Flex>
    <Flex onClick={e => sortdate('tempdate',order,list,setList,setOrder)} className="hand">Date Delivered</Flex> 
    <Flex flex=".5"></Flex> 
</Flexed>
  <Scroll >
    
{

// list?.filter(ite => {return search?.toLowerCase() === '' ? ite : ite.firstName.toLowerCase().includes(search.toLowerCase()) || ite.lastName.toLowerCase().includes(search.toLowerCase()) || ite.preferredName?.toLowerCase().includes(search.toLowerCase()) || ite.empId.includes(search)}).map


    newlist?.map((item,index) => {
 
return <div style={{marginBottom:"8px"}}>
    <Collapse title={<Flexed padding="5px 0px 3px 0px"  fontSize="calc(.9rem + .2vw)" bgColor="#CFCDCC" >
        <Flex flex=".3" textAlign="right" ><IoMdAddCircle  style={{fontSize:"calc(1.2rem + .2vw)",color:"var(--plsGreen)"}} /> </Flex>
        <Flex flex=".7">{item.jobnum}</Flex>
        <Flex flex=".7">{item.shipid}</Flex>
<Flex flex="3">{item.tempcustomer}</Flex>

    <Flex flex="3">{item?.tempdescription}</Flex>
    <Flex >{item.tempordered}</Flex>
    <Flex >{item.tempsent}</Flex>
    <Flex ><Moment style={{fontSize:"calc(1.2rem +.2vw)"}} format="MM/DD/YYYY">{item?.dateshipped !== null ? new Date(item?.dateshipped) : new Date(item.createdAt)}</Moment></Flex>  
  
</Flexed>}>
<div style={{border:"1px solid #8398AB",marginBottom:"8px"}}>
<Flexed  borderBottom="1px solid black" fontSize="calc(1.1rem + .2vw)" style={{color:"white",padding:"5px 0px",background:"#8398AB"}}>
<Flex flex="3">Item</Flex>
    <Flex flex="3">Description</Flex>
    <Flex >Ordered</Flex>
    <Flex >Delivered</Flex>
    <Flex >Date Delivered</Flex>  
    <Flex flex=".5" display="flex" alignItems="flex-start">{<><div className="hand" onClick={e => nav('/whitesheet/'+item.jobnum)}>Reprint</div></>}</Flex>
</Flexed>
{
    item.items?.map(shippeditem => {
    return    <Flexed padding="6px 0px"  fontSize="calc(.9rem + .2vw)" color="black" borderBottom="1px solid #8398AB">
<Flex flex="3">{shippeditem.name}</Flex>
    <Flex flex="3">{shippeditem.description}</Flex>
    <Flex >{shippeditem.requested}</Flex>
    <Flex >{shippeditem.sending}</Flex>
    <Flex ><Moment style={{fontSize:"calc(1.2rem +.2vw)"}} format="MM/DD/YYYY">{item?.dateshipped !== null ? new Date(item?.dateshipped) : new Date(item.createdAt)}</Moment></Flex>  
    <Flex flex=".5">&nbsp;</Flex>
</Flexed>
    })
}
</div>
</Collapse>
</div>
    })

}

    </Scroll>
    </MainWrap>
  )
}

export default Shiplog

	
